import { ApolloProvider } from "@apollo/client";
import { LoggedProfileHandler } from "@shared/components/LoggedProfileHandler";
import { ABTestingProvider, AB_TESTING_VARIANT_PROP_NAME } from "@shared/lib/ABTesting";
import { useApollo } from "@shared/lib/Apollo/apolloClient";
import { USER_AGENT_PROP_NAME, UserAgentProvider } from "@shared/lib/UAParser";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { getFlashMessagesRef } from "@shared/services/FlashMessagesService";
import { sendABTestingEvent } from "@shared/utils/ab-testing";
import { FlashMessages } from "@ui/components/FlashMessages/FlashMessages";
import "@ui/utils/global-styles.css";
import "core-js/features/object/from-entries"; // solves incompatibility Object.fromEntries() of react-laag on older Safari
import "focus-visible";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Router from "next/router";
import Nprogress from "nprogress";
import { useEffect } from "react";

type UxfAppProps = AppProps<{ __AB_TESTING_VARIANT__: any; __USER_AGENT__: any }> & {
    err?: any;
    apolloError?: any;
};

const BackofficeToolbar = dynamic(
    () => import("@admin-zone/components/BackofficeToolbar").then((component) => component.BackofficeToolbar),
    { ssr: false },
);

function UxfApp(props: UxfAppProps) {
    const { Component, pageProps, err, apolloError } = props;
    const apolloClient = useApollo(pageProps);

    // Nprogress
    useEffect(() => {
        Nprogress.configure({ showSpinner: false });
        const onRouteChangeStart = () => Nprogress.start();
        const onRouteChangeStop = () => Nprogress.done();
        const onRouteChangeComplete = () => {
            Nprogress.done();
            window.scrollTo(0, 0);
        };

        Router.events.on("routeChangeStart", onRouteChangeStart);
        Router.events.on("routeChangeComplete", onRouteChangeComplete);
        Router.events.on("routeChangeError", onRouteChangeStop);

        return () => {
            Router.events.off("routeChangeStart", onRouteChangeStart);
            Router.events.off("routeChangeComplete", onRouteChangeComplete);
            Router.events.off("routeChangeError", onRouteChangeStop);
        };
    }, []);

    // Analytics
    useEffect(() => {
        const landingUrl = decodeURI(window.location.href);
        AnalyticsService.setLandingURL(landingUrl);
        AnalyticsService.setUtmParameters(decodeURIComponent(window.location.search));
        AnalyticsService.setScreenDimensions(window.screen.width, window.screen.height);
        AnalyticsService.event({
            event: "InteractionUI",
            eventCategory: "Vstup_na_web",
            eventAction: "Enter",
            eventLabel: "LP_URL",
            eventValue: landingUrl,
        });

        sendABTestingEvent();
    }, []);

    return (
        <ApolloProvider client={apolloClient}>
            <ABTestingProvider variant={pageProps[AB_TESTING_VARIANT_PROP_NAME]}>
                <UserAgentProvider userAgent={pageProps[USER_AGENT_PROP_NAME]}>
                    <>
                        <Component {...pageProps} err={err} apolloError={apolloError} />
                        <FlashMessages ref={getFlashMessagesRef()} />
                        <LoggedProfileHandler />
                        <BackofficeToolbar />
                    </>
                </UserAgentProvider>
            </ABTestingProvider>
        </ApolloProvider>
    );
}

export default UxfApp;
