import { config } from "@config/config";
import { useSaveMarketingProfileMutation } from "@gql-schema/MarketingMutations";
import { useCallback } from "react";
import { prepareMarketingProfileInput } from "./prepareMarketingProfileInput";
import { SaveMarketingProfileData } from "./types";

export const useSaveMarketingProfile = () => {
    const [mutation] = useSaveMarketingProfileMutation();

    return useCallback(
        (rawData: SaveMarketingProfileData) => {
            const data = prepareMarketingProfileInput(rawData);
            mutation({ variables: { data } });
            if (config.STAGE !== "prod") {
                // eslint-disable-next-line no-console
                console.log("📘 DEBUG MKTG PROFILE DATA: ", data);
            }
        },
        [mutation],
    );
};
