import { AnalyticsService } from "@shared/services/AnalyticsService";
import { useSaveMarketingProfile } from "@shared/utils/marketingProfile/useSaveMarketingProfile";
import { createContext, ReactElement, useContext, useEffect, useMemo } from "react";
import UAParser, { IResult } from "ua-parser-js";

export const UA_DEVICE_TYPES = ["console", "mobile", "tablet", "smarttv", "wearable", "embedded"];
export const USER_AGENT_PROP_NAME = "__USER_AGENT__";

const UAParserContext = createContext({} as Partial<IResult> | undefined);

export const useUAParserContext = (): Partial<IResult> | undefined => {
    return useContext(UAParserContext);
};

export const useIsDesktop = () => useUAParserContext()?.device?.type === "desktop";

export const useIsMobile = () => useUAParserContext()?.device?.type === "mobile";

export const useIsNotMobile = () => useUAParserContext()?.device?.type !== "mobile";

export const UserAgentProvider: React.FC<{
    userAgent: string | null | undefined;
    children: ReactElement;
}> = (props) => {
    const { userAgent, children } = props;
    const saveMarketingProfile = useSaveMarketingProfile();

    const parsedUserAgent: Partial<IResult> = useMemo(
        () => (userAgent ? new UAParser(userAgent).getResult() : {}),
        [userAgent],
    );

    useEffect(() => {
        const deviceTypeString = parsedUserAgent.device?.type ?? "";
        const deviceType = UA_DEVICE_TYPES.includes(deviceTypeString) ? deviceTypeString : "desktop";

        if (AnalyticsService.getDeviceType() !== deviceType) {
            AnalyticsService.setDeviceType(deviceType);
            saveMarketingProfile({
                eventName: "Zarizeni",
                eventValueText1: deviceType,
            });
        }
    }, [parsedUserAgent, saveMarketingProfile]);

    return <UAParserContext.Provider value={parsedUserAgent}>{children}</UAParserContext.Provider>;
};
