import { UxfPageContext } from "@app-routes";
import { MarketingMarketingProfileSaveInput } from "@gql-schema";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { Cookie } from "@uxf/core/cookie";
import { SaveMarketingProfileData } from "./types";

export function prepareMarketingProfileInput(
    rawData: SaveMarketingProfileData,
    ctx?: UxfPageContext<any>,
): MarketingMarketingProfileSaveInput {
    const cookie = new Cookie(ctx);

    const analyticsSessionId = cookie.get("Analytics-Session-Id");
    const { utmCampaign, utmSource, utmMedium } = AnalyticsService.getUtmParameters();
    const landingUrl = AnalyticsService.getLandingUrl();

    return {
        utmCampaign,
        utmSource,
        utmMedium,
        landingUrl,
        ...rawData,
        eventValueText2: analyticsSessionId,
        eventSource: "WEB",
    };
}
