import { UxfPageContext } from "@app-routes";
import * as GQL from "@gql-schema";
import { SaveMarketingProfileDocument } from "@gql-schema/MarketingMutations";
import { ApolloClient } from "@shared/lib/Apollo/types";
import { prepareMarketingProfileInput } from "./prepareMarketingProfileInput";
import { SaveMarketingProfileData } from "./types";

export const GCLID_NAME = "g_c_fan";

export const saveMarketingProfile = (
    rawData: SaveMarketingProfileData,
    apollo: ApolloClient,
    ctx?: UxfPageContext<any>,
) => {
    apollo.mutate<GQL.SaveMarketingProfileMutation, GQL.SaveMarketingProfileMutationVariables>({
        mutation: SaveMarketingProfileDocument,
        variables: { data: prepareMarketingProfileInput(rawData, ctx) },
    });
};
