/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
export const SmartAddressFragmentDoc = gql`
    fragment SmartAddress on SmartAddress {
  id
  street: ulice
  city: obec
  zip: psc
  level
  gps {
    latitude
    longitude
  }
}
    `;
export const NewSmartAddressFragmentDoc = gql`
    fragment NewSmartAddress on NewSmartAddress {
  id
  street
  city
  zip
  level
  gps {
    latitude
    longitude
  }
}
    `;
export const SmartAddressObjectTypeFragmentDoc = gql`
    fragment SmartAddressObjectType on SmartAddressObjectType {
  id
  nazev
}
    `;
export const SmartAddressExtendedInfoFragmentDoc = gql`
    fragment SmartAddressExtendedInfo on SmartAddressExtendedInfo {
  id
  objectType {
    ...SmartAddressObjectType
  }
}
    ${SmartAddressObjectTypeFragmentDoc}`;
export const SmartAddressAddressExtendedAdministrativeResponseFragmentDoc = gql`
    fragment SmartAddressAddressExtendedAdministrativeResponse on SmartAddressAddressExtendedAdministrativeResponse {
  id
  objectType {
    ...SmartAddressObjectType
  }
}
    ${SmartAddressObjectTypeFragmentDoc}`;
export const SmartAddressSdkExtendedInfoFragmentDoc = gql`
    fragment SmartAddressSdkExtendedInfo on SmartAddressSdkExtendedInfo {
  administrativniUdaje {
    ...SmartAddressAddressExtendedAdministrativeResponse
  }
}
    ${SmartAddressAddressExtendedAdministrativeResponseFragmentDoc}`;