const create = () => {
    const STAGE = process.env.NEXT_PUBLIC_STAGE || "local";
    return {
        DEBUG: STAGE === "local",
        STAGE: STAGE,
        ALLOWED_LANGUAGES: process.env.ALLOWED_LANGUAGES,
        AUTHORIZATION_HEADER: process.env.AUTHORIZATION_HEADER || "Authorization",
        AUTHORIZATION_HEADER_ADMIN: process.env.AUTHORIZATION_HEADER_ADMIN || "Authorization",
        DEFAULT_LANGUAGE: process.env.DEFAULT_LANGUAGE,
        FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
        FRONTEND_URL: process.env.FRONTEND_URL,
        GTM_ID: process.env.GTM_ID,
        NODE_ENV: process.env.NODE_ENV,
        PROXY_ENABLED: process.env.PROXY_ENABLED,
        PROXY_API_URL: process.env.PROXY_API_URL,
        ENERGO_KALKULATOR_URL: process.env.ENERGO_KALKULATOR_URL,
        LAST_LOGIN_COOKIE: "Last-Login",
        PROFILE_UUID_HEADER: "Profile-Uuid",
        PROFILE_UUID_TTL: 3600 * 24 * 365 * 5,
        BROWSER_SESSION_UUID_HEADER: "Browser-Session-Uuid",
        BROWSER_SESSION_UUID_TTL: 172800, // 2 days
        ELK_ANALYTICS_ENABLED:
            process.env.ELK_ANALYTICS_ENABLED === "true" || process.env.ELK_ANALYTICS_ENABLED === "1",
        PROXY_ELK_ANALYTICS_URL: process.env.PROXY_ELK_ANALYTICS_URL,
        FACEBOOK_PAGE_URL: "https://www.facebook.com/kalkulator.cz.sro",
        LINKEDIN_PAGE_URL: "https://www.linkedin.com/company/kalkulator-cz-s-r-o/",
        WDYR: process.env.WDYR,
        ENABLE_AB_TESTING: false,
    };
};

export const ConfigFactory = {
    create,
};
