import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { config } from "@config/config";
import { customFetch } from "@shared/lib/Apollo/customFetch";
import merge from "deepmerge";
import isEqual from "lodash.isequal";
import { GetServerSidePropsContext, GetStaticPropsResult, NextPageContext } from "next";
import { useMemo } from "react";

type NextContext = GetServerSidePropsContext | NextPageContext | null | undefined;

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<any> | null;

const uploadProgressLink = setContext((_, prevContext) => {
    return {
        ...prevContext,
        onProgress: prevContext.onProgress,
    };
});

const authLink = (ctx: NextContext | null) =>
    setContext(() => {
        // client side
        if (typeof window !== "undefined") {
            return {};
        }

        // getServerSideProps, getInitialProps
        if (ctx?.req?.headers.cookie) {
            return {
                headers: { cookie: ctx.req.headers.cookie },
            };
        }

        // getStaticProps
        return {
            headers: {
                [config.PROFILE_UUID_HEADER]: "31bc4279-3ac8-44e2-b3ad-1dccc386a7b8",
                [config.BROWSER_SESSION_UUID_HEADER]: "31bc4279-3ac8-44e2-b3ad-1dccc386a7b8",
            },
        };
    });

const getDefaultIdOrUuid = (value: any) => {
    const defaultId = defaultDataIdFromObject(value);
    if (!defaultId && value.uuid) {
        return `${value.__typename}:${value.uuid}`;
    }
    return defaultId;
};

function createApolloClient(ctx: NextContext | null) {
    return new ApolloClient({
        ssrMode: typeof window === "undefined",
        link: ApolloLink.from([
            authLink(ctx),
            uploadProgressLink,
            new HttpLink({ uri: `${config.FRONTEND_URL}/graphql`, fetch: customFetch }),
        ]),
        cache: new InMemoryCache({
            dataIdFromObject: (value) => {
                switch (value.__typename) {
                    case "Address":
                        return `${value.__typename}:${(value as any).uniqueId}`;
                    case "CodeBook":
                        return `${value.__typename}:${(value as any).id}:${(value as any).label}:${
                            (value as any).tooltipText
                        }`;
                    case "ZeteoAddress":
                        return `${value.__typename}:${(value as any).id}:${(value as any).zipCode}`;
                    case "EnergoElectricityResultItem":
                    case "EnergoGasResultItem":
                        return `${value.__typename}:${(value as any).id}:${(value as any).saving}`;
                    default:
                        return getDefaultIdOrUuid(value);
                }
            },
        }),
    });
}

export function initializeApollo(ctx: NextContext | null, initialState: any = null) {
    const _apolloClient = apolloClient ?? createApolloClient(ctx);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") {
        return _apolloClient;
    }
    // Create the Apollo Client once in the client
    if (!apolloClient) {
        apolloClient = _apolloClient;
    }

    return _apolloClient;
}

export function addApolloState<P>(client: ApolloClient<any>, pageProps: GetStaticPropsResult<P>) {
    if ((pageProps as any).props !== undefined) {
        (pageProps as any).props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps: any) {
    const state = pageProps[APOLLO_STATE_PROP_NAME] ?? pageProps?.apolloState?.data;

    return useMemo(() => {
        // next-with-apollo hack (getDataFromTree)
        if (typeof window === "undefined" && pageProps?.apollo) {
            return pageProps.apollo;
        }

        return initializeApollo(null, state);
    }, [pageProps?.apollo, state]);
}
