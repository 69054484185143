/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { SmartAddressExtendedInfoFragmentDoc, SmartAddressSdkExtendedInfoFragmentDoc } from './SmartAddressFragments.generated';
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  uuid
  type
  extension
  namespace
  name
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  name
  uuid
  type
  createdAt
  extension
  height
  namespace
  size
  width
}
    `;
export const CodebookFragmentDoc = gql`
    fragment Codebook on Codebook {
  id
  label
}
    `;
export const MoneyFragmentDoc = gql`
    fragment Money on Money {
  currency
  amount
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  id
  uniqueId
  smartAddressId
  street
  descriptiveNumber
  orientationNumber
  orientationNumberCharacter
  city
  zip
  cityPart
  district
  region
  level
  extended {
    ...SmartAddressExtendedInfo
  }
  extendedV2 {
    ...SmartAddressSdkExtendedInfo
  }
}
    ${SmartAddressExtendedInfoFragmentDoc}
${SmartAddressSdkExtendedInfoFragmentDoc}`;
export const CustomAddressFragmentDoc = gql`
    fragment CustomAddress on CustomAddress {
  address
  zipCode
  city
}
    `;